<template>
  <div>
    <BModal
      ref="modal-change-delivered"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
    >
      <BRow class="px-1 py-3">
        <BCol cols="12" class="d-flex justify-content-center mb-2">
          <BImg
            src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
            width="90"
          />
        </BCol>
        <BCol cols="12" class="mb-1">
          <h3 class="text-black text-center mb-0">
            <strong>
              {{
                confirmed
                  ? 'Mengubah ke Paket Diterima'
                  : 'Ubah ke Paket Diterima'
              }}
            </strong>
          </h3>
        </BCol>
        <BCol cols="12" class="mb-2 text-center">
          <span v-if="!confirmed" class="text-black">
            Lampirkan bukti pendukung bahwa paket tersebut memang sudah diterima
            Penerima
          </span>
          <span v-else class="text-black">
            Orderan ini akan diubah statusnya menjadi
            <strong>Paket Diterima</strong>. Dampaknya akan membuat status tiket
            ini jadi Diterima dan meneruskan Nett Profit ke Saldo KomPay Partner
          </span>
        </BCol>
        <BCol v-if="!confirmed" cols="12" class="mb-2">
          <div class="mb-1">
            <small class="text-black"> Link Video Bukti </small>
            <BInputGroup>
              <template #append>
                <BInputGroupText
                  ><span class="divider-url"></span
                  ><span class="text-primary">Link</span></BInputGroupText
                >
              </template>
              <BFormInput
                style="border-right: 0px"
                type="text"
                v-model="url"
                @input="validationUrl"
              ></BFormInput>
            </BInputGroup>
            <small v-if="urlError === 'invalid'" class="text-primary"
              >URL tidak valid</small
            >
          </div>
          <div class="d-flex flex-column">
            <small class="text-black"> Lampirkan Bukti </small>
            <label
              for="add-file"
              class="wrapper-add-file d-flex align-items-center justify-content-between px-1 w-100"
              :style="
                fileList.length < 3 ? 'cursor: pointer;' : 'cursor: not-allowed'
              "
            >
              <div>Pilih File</div>
              <span class="divider-file"></span>
              <div class="text-primary">File</div>
            </label>
            <input
              id="add-file"
              name="img"
              accept="image/x-png,image/jpg,image/jpeg"
              type="file"
              multiple
              hidden
              @input="addFile($event)"
              :disabled="fileList.length < 3 ? false : true"
            />
            <small v-if="fileSizeExceeded" class="text-primary"
              >Ukuran file maksimal 2mb</small
            >
          </div>
        </BCol>
        <BCol v-if="!confirmed" cols="12" class="mb-2 d-flex flex-wrap">
          <div v-for="(item, index) in fileList" :key="index">
            <div class="wrapper-preview-img m-1">
              <BImg
                :src="previewFile(item)"
                width="40"
                class="preview-img"
                @click="openPreview(item)"
              />
              <BButton
                variant="flat-dark"
                size="sm"
                class="btn-icon p-0 close-preview-img"
                @click="removeFile(index)"
              >
                <BImg
                  src="https://storage.googleapis.com/komerce/assets/icons/close-circle.svg"
                  width="16"
                />
              </BButton>
            </div>
          </div>
        </BCol>
        <BCol cols="12" class="text-center d-flex align-items-center">
          <BButton
            variant="outline-primary"
            class="action-btn mr-1"
            @click="confirmed ? back() : hide()"
          >
            <strong>
              {{ confirmed ? 'Kembali' : 'Batal' }}
            </strong>
          </BButton>
          <BButton
            :variant="(!url && fileList.length === 0) || urlError !== '' ? 'dark' : 'primary'"
            :disabled="(!url && fileList.length === 0) || urlError !== ''"
            class="action-btn d-flex justify-content-center align-items-center"
            @click="!confirmed ? changed() : submit()"
            :style="
              (!url && fileList.length === 0) || urlError !== ''
                ? 'cursor: not-allowed;'
                : 'cursor: pointer;'
            "
          >
            <BSpinner v-if="loading" small class="mr-50" />
            <strong> Ubah Status Paket </strong>
          </BButton>
        </BCol>
      </BRow>
    </BModal>

    <ModalPreviewImg ref="preview-img-modal" :img="img"></ModalPreviewImg>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ModalPreviewImg from './ModalPreviewImg.vue'

const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/

export default {
  props: ['orderId'],
  components: { ModalPreviewImg },
  data() {
    return {
      loading: false,
      confirmed: false,
      fileList: [],
      url: null,
      urlError: '',
      img: null,
      maxFileSize: 2000000,
      fileSizeExceeded: false,
    }
  },
  methods: {
    back() {
      this.loading = false
      this.confirmed = false
    },
    show() {
      this.$refs['modal-change-delivered'].show()
    },
    hide() {
      this.fileList = []
      this.confirmed = false
      this.loading = false
      this.url = null
      this.urlError = ''
      this.$refs['modal-change-delivered'].hide()
    },
    changed() {
      this.confirmed = true
    },
    addFile($event) {
      Array.from($event.target.files).forEach(file => {
        if (this.fileList.length < 3) {
          if (
            file.type === 'image/jpeg'
            || file.type === 'image/jpg'
            || file.type === 'image/png'
          ) {
            if (!file.name.includes('jfif')) {
              if (file.size < this.maxFileSize) {
                this.fileSizeExceeded = false
                this.fileList.push({ file })
              } else {
                this.fileSizeExceeded = true
              }
            }
          }
        }
      })
    },
    removeFile(index) {
      this.fileList.splice(index, 1)
    },
    previewFile(file) {
      return URL.createObjectURL(file.file)
    },
    openPreview(file) {
      this.img = URL.createObjectURL(file.file)
      this.$refs['preview-img-modal'].show()
    },
    submit() {
      this.loading = true
      const formData = {
        data: new FormData(),
        orderId: this.orderId,
      }
      formData.data.append('proof_url_video', this.url)
      for (let i = 0; i < this.fileList.length; i += 1) {
        formData.data.append('proof_images', this.fileList[i].file)
      }
      this.$store.dispatch('searchOrder/forceDelivered', formData).then(
        () => {
          this.setDefaultValue()
          this.hide()
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Berhasil',
                icon: 'CheckIcon',
                text: 'Berhasil mengubah paket diterima',
                variant: 'success',
              },
            },
            2000,
          )
          window.location.reload()
        },
        err => {
          this.loading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.data.meta.message,
                variant: 'primary',
              },
            },
            2000,
          )
        },
      )
    },
    setDefaultValue() {
      this.fileList = []
      this.confirmed = false
      this.loading = false
    },
    validationUrl() {
      if (this.url !== '') {
        if (!urlPattern.test(this.url)) {
          this.urlError = 'invalid'
        } else {
          this.urlError = ''
        }
      } else {
        this.urlError = ''
      }
    },
  },
}
</script>

<style scoped>
.wrapper-preview-img {
  position: relative;
}

.preview-img {
  cursor: pointer;
  height: 40px;
}

.close-preview-img {
  position: absolute;
  top: -10px;
  right: -10px;
}

.action-btn {
  width: 260px;
  height: 35px;
  letter-spacing: 1px;
  border-radius: 8px;
}

.wrapper-add-file {
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  height: 40px;
  color: #c2c2c2;
  font-size: 14px;
  position: relative;
}

.divider-file,
.divider-url {
  width: 0.5px;
  height: 25px;
  background: #e2e2e2;
  position: absolute;
  right: 12%;
}
</style>
