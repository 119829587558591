<template>
  <BModal
    id="print-label"
    size="xl"
    scrollable
  >
    <template #modal-header>
      <div class="w-100">
        <b-img
          src="@/assets/images/icons/close-circle.svg"
          class="absolute right-3"
          role="button"
          @click="$bvModal.hide('print-label')"
        />
        <div class="text-center font-bold text-xl text-black my-1">
          Pilih Bentuk Print Label
        </div>
      </div>
    </template>
    <BRow class="justify-content-center">
      <BCol cols="auto">
        <BCard
          class="card-print-label"
          :class="formatPrint === 'page_1' ? 'active' : ''"
          role="button"
          @click="formatPrint = 'page_1'"
        >
          <div class="label-preview">
            <div class="label-preview-top" />
          </div>
          <p class="text-[#222222] text-[14px] font-semibold my-1">
            1 Label / Halaman
          </p>
          <p class="text-[#828282] text-[14px] font-medium mb-0">
            Direkomandasikan kertas A4
          </p>
        </BCard>
      </BCol>
      <BCol cols="auto">
        <BCard
          class="card-print-label"
          :class="formatPrint === 'page_2' ? 'active' : ''"
          role="button"
          @click="formatPrint = 'page_2'"
        >
          <div class="label-preview">
            <div class="label-preview-top" />
            <div class="label-preview-bottom" />
          </div>
          <p class="text-[#222222] text-[14px] font-semibold my-1">
            2 Label / Halaman
          </p>
          <p class="text-[#828282] text-[14px] font-medium mb-0">
            Direkomandasikan kertas A4
          </p>
        </BCard>
      </BCol>
      <BCol cols="auto">
        <BCard
          class="card-print-label"
          :class="formatPrint === 'page_4' ? 'active' : ''"
          role="button"
          @click="formatPrint = 'page_4'"
        >
          <div class="label-preview">
            <div class="label-preview-topleft" />
            <div class="label-preview-topright" />
            <div class="label-preview-bottomleft" />
            <div class="label-preview-bottomright" />
          </div>
          <p class="text-[#222222] text-[14px] font-semibold my-1">
            4 Label / Halaman
          </p>
          <p class="text-[#828282] text-[14px] font-medium mb-0">
            Direkomandasikan kertas A4
          </p>
        </BCard>
      </BCol>
      <BCol cols="auto">
        <BCard
          class="card-print-label"
          :class="formatPrint === 'page_5' ? 'active' : ''"
          role="button"
          @click="formatPrint = 'page_5'"
        >
          <div class="label-preview">
            <div class="label-preview-full" />
          </div>
          <p class="text-[#222222] text-[14px] font-semibold my-1">
            10 cm x 10cm
          </p>
          <p class="text-[#828282] text-[14px] font-medium mb-0">
            Printer Thermal
          </p>
        </BCard>
      </BCol>
      <BCol cols="auto">
        <BCard
          class="card-print-label"
          :class="formatPrint === 'page_6' ? 'active' : ''"
          role="button"
          @click="formatPrint = 'page_6'"
        >
          <div class="px-2">
            <div class="label-preview">
              <div class="label-preview-full" />
            </div>
          </div>
          <p class="text-[#222222] text-[14px] font-semibold my-1">
            10 cm x 15cm
          </p>
          <p class="text-[#828282] text-[14px] font-medium mb-0">
            Printer Thermal
          </p>
        </BCard>
      </BCol>
    </BRow>
    <template #modal-footer>
      <div class="d-flex justify-end gap-5">
        <BFormCheckbox
          v-model="printDate"
          class="my-auto"
        >
          <span class="text-black font-bold"> Tambahkan tanggal cetak</span>
        </BFormCheckbox>
        <BButton
          variant="primary"
          class=""
          :disabled="formatPrint === '' || disableBtn"
          @click="onFinish"
        >
          <BSpinner
            v-if="loading"
            small
            variant="light"
            class="mr-1"
          />
          <span
            v-if="loading"
            class="font-semibold"
          >
            Downloading...
          </span>
          <span
            v-else
            class="font-semibold"
          >
            Download Label
          </span>
        </BButton>
      </div>
    </template>
  </BModal>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import { newAxiosIns } from '@/libs/axios'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formatPrint: '',
      loading: false,
      printDate: false,
      disableBtn: false,
    }
  },
  methods: {
    async onFinish() {
      this.loading = true
      this.disableBtn = true
      const { order_id } = this.data
      const formData = new FormData()
      formData.append('order_id', order_id)
      formData.append('page', this.formatPrint)
      if (this.printDate) formData.append('print_date', 1)
      const url = '/label/api/v2/generate/print-label'
      await newAxiosIns.post(url, formData)
        .then(res => {
          this.loading = false
          this.disableBtn = false
          const { base_64, path } = res.data.data
          const doc = document.createElement('a')
          // eslint-disable-next-line camelcase
          doc.href = `data:application/pdf;base64,${base_64}`
          doc.download = `label-${moment().format('YYYY-DD-MM')}.pdf`
          doc.click()
          window.open(path, '_blank')
        })
        .catch(() => {
          this.loading = false
          this.disableBtn = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal download label, silahkan coba lagi',
              variant: 'danger',
            },
          }, { timeout: 1000 })
        })
    },
  },
}
</script>

<style scoped lang="scss">
@import './Print.scss';
</style>
