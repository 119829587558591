<!-- eslint-disable vue/no-v-html -->
<template>
  <BModal
    hide-footer
    hide-header
    :centered="true"
    v-bind="$attrs"
    lazy
    body-class="p-0"
  >
    <div class="flex flex-col justify-center items-center text-center gap-8 px-[32px] py-[56px]">
      <img
        src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
        alt="Komerce"
        width="100"
        class="mb-8"
      >
      <div class="flex flex-col gap-[4px]">
        <h4 class="font-semibold text-[24px] text-black">
          {{ title }}
        </h4>
        <p
          class="text-[#222222]"
          v-html="message"
        />
      </div>
      <div class="flex justify-center items-center gap-[4px] w-full">
        <BButton
          variant="outline-primary"
          class="flex-[45%]"
          @click="$emit('on-click-cancel-button', $event)"
        >
          {{ labelCancelButton }}
        </BButton>

        <BButton
          variant="primary"
          class="flex-[45%]"
          :disabled="loadingConfirmButton"
          @click="$emit('on-click-confirm-button', $event)"
        >
          <BSpinner
            v-if="loadingConfirmButton"
            small
          />
          {{ labelConfirmButton }}
        </BButton>

      </div>
    </div>
  </BModal>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    labelConfirmButton: {
      type: String,
      default: 'Ya',
      required: false,
    },
    labelCancelButton: {
      type: String,
      default: 'Batal',
      required: false,
    },
    loadingConfirmButton: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
