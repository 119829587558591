import { render, staticRenderFns } from "./ModalChangeDelivered.vue?vue&type=template&id=22f47a55&scoped=true&"
import script from "./ModalChangeDelivered.vue?vue&type=script&lang=js&"
export * from "./ModalChangeDelivered.vue?vue&type=script&lang=js&"
import style0 from "./ModalChangeDelivered.vue?vue&type=style&index=0&id=22f47a55&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22f47a55",
  null
  
)

export default component.exports