<template>
  <div>
    <div class="text-2xl text-black font-semibold">
      Cari Order & Label
    </div>
    <BRow class="mt-1">
      <BCol
        lg="4"
        class="flex"
      >
        <BFormInput
          v-model="search"
          size="md"
          placeholder="Cari No Order / Resi"
          class="mr-1"
          @keyup.enter="searchOrder"
        />
        <BButton
          :variant="search === '' ? 'secondary' : 'primary'"
          size="sm"
          :disabled="search === ''"
          @click="searchOrder"
        >
          <b-icon-search />
        </BButton>
      </BCol>
    </BRow>

    <BCard
      v-if="showDataOrder"
      class="w-full mt-2"
    >
      <BContainer>
        <BRow
          v-for="(value, key, index) in detailPartner"
          :key="index"
          class="mb-[1rem] last:mb-0"
        >
          <BCol cols="3">
            {{ key }}
          </BCol>
          <BCol cols="0.4">
            :
          </BCol>
          <BCol class="text-[#222222]">
            {{ value }}
          </BCol>
        </BRow>
      </BContainer>
    </BCard>

    <BRow v-if="showMessage">
      <BCol
        lg="8"
        class="flex items-center bg-[#FFF2E2] mt-2 p-[12px]"
      >
        <img
          src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/warning-2.svg"
          alt="Komerce"
        >
        <span class="text-[#222222] ml-1">{{ messageNotFound }}</span>
      </BCol>
    </BRow>
    <BRow v-if="checkConnection === false">
      <BCol
        lg="8"
        class="flex items-center bg-[#FFECE9] mt-2 p-[12px]"
      >
        <img
          src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/warning-2.svg"
          alt="Komerce"
        >
        <span class="text-[#222222] ml-1">Coba periksa koneksi kamu, dan coba lagi</span>
      </BCol>
    </BRow>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <DetailOrder
        v-if="showDataOrder"
        :data="data"
        :fields="fields"
        :items="items"
        :status-order="statusOrder"
        :status-net-profit="statusNetProfit"
        @search-order="searchOrder"
      />
    </BOverlay>
  </div>
</template>

<script>
import { komshipAxiosIns } from '@/libs/axios'
import DetailOrder from './DetailOrder.vue'
import { columns } from './config'

export default {
  components: { DetailOrder },
  data() {
    return {
      search: '',
      messageNotFound: '',
      showMessage: false,
      showDataOrder: false,
      data: {},
      fields: columns,
      items: [],
      statusOrder: null,
      statusNetProfit: null,
      loading: false,
      checkConnection: window.navigator.onLine,
    }
  },
  computed: {
    detailPartner() {
      return {
        'Nama Partner': this.data.partner_information.partner_name || '-',
        Email: this.data.partner_information.partner_email || '-',
        'No Handphone': this.data.partner_information.partner_phone_number || '-',
      }
    },
  },
  methods: {
    async searchOrder() {
      this.loading = true
      const params = `q=${this.search}`
      const url = `/v1/admin/order/search?${params}`
      await komshipAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.loading = false
          this.showDataOrder = true
          this.data = data
          this.items = data.product
          this.statusOrder = this.setAlert(data.order_status)
          this.statusNetProfit = data.net_profit.toString().charAt(0)
          this.showMessage = false
        })
        .catch(() => {
          this.showMessage = true
          this.showDataOrder = false
          this.loading = false
          this.messageNotFound = 'Order data tidak ditemukan'
        })
    },
    setAlert(status) {
      if (status === 'Diajukan') {
        this.statusOrder = 'primary'
      } else if (status === 'Dipacking') {
        this.statusOrder = 'info'
      } else if (status === 'Dikirim') {
        this.statusOrder = 'warning'
      } else if (status === 'Diterima') {
        this.statusOrder = 'success'
      } else if (status === 'Retur') {
        this.statusOrder = 'danger'
      } else if (status === 'Batal') {
        this.statusOrder = 'dark'
      }
      return this.statusOrder
    },
  },
}
</script>

<style scoped>
</style>
