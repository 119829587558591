<template>
  <BModal
    ref="modal-preview-img"
    centered
    hide-header
    hide-footer
    size="xl"
  >
    <BRow class="justify-content-center my-4">
      <BImg :src="img" />
    </BRow>
  </BModal>
</template>

<script>
export default {
  props: ['img'],
  methods: {
    show() {
      this.$refs['modal-preview-img'].show()
    },
    hide() {
      this.$refs['modal-preview-img'].hide()
    },
  },
}
</script>

<style>
</style>
