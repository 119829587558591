<template>
  <BModal
    id="tracking-resi"
    hide-footer
    modal-class="modal-primary"
    centered
    hide-header
    size="lg"
  >
    <HistoryShipping
      :awb="awb"
    >
      <template v-slot:footer>
        <p
          v-if="claimMessageData.message"
          :class="['text-center my-[8px]', claimMessageData.className]"
        >
          {{ claimMessageData.message }}
        </p>
      </template>
    </HistoryShipping>
  </BModal>
</template>

<script>
import HistoryShipping from '../../tiket/HistoryShipping.vue'

export default {
  components: {
    HistoryShipping,
  },
  data: () => ({
    isShowClaimMessage: false,
  }),
  props: {
    awb: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    claimMessageData() {
      let message = ''
      let className = ''
      // eslint-disable-next-line default-case
      switch (this.data.status_claim_lost) {
        case 0:
          message = 'Claim Sedang Direview'
          className = 'text-[#FBA63C]'
          break

        case 1:
          message = 'Claim Disetujui'
          className = 'text-[#34A770]'
          break

        case 2:
          message = 'Claim Ditolak'
          className = 'text-[#E31A1A]'
          break
      }

      return { message, className }
    },
  },
}
</script>
