/* eslint-disable import/prefer-default-export */
export const columns = [
  {
    key: 'no',
    label: 'No',
    tdClass: 'text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
  },
  {
    key: 'product_name',
    label: 'Nama Produk',
    tdClass: 'text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
  },
  {
    key: 'price',
    label: 'Harga Satuan',
    tdClass: 'text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
  },
  {
    key: 'qty',
    label: 'Jumlah',
    tdClass: 'text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
  },
  {
    key: 'subtotal',
    label: 'Sub Total',
    tdClass: 'text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
  },
]
